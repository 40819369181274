import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

import COLORS from './Colors';
import logo from './caebes-logo.svg';
import SECRETS from './secrets';

const containerStyle = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.SLATE700,
};

const alertStyle = {
  width: '100%',
  maxWidth: '300px',
  padding: '12px',
  borderRadius: '3px',
  backgroundColor: COLORS.RED100,
  color: COLORS.RED700,
};

const formStyle = {
  maxWidth: '600px',
  minHeight: '600px',
  backgroundColor: COLORS.SLATE200,
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const inputContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '50px',
};

const inputStyle = {
  height: '50px',
  width: '300px',
  borderRadius: '3px',
  border: 0,
  margin: '10px',
  fontSize: '20px',
  backgroundColor: COLORS.SLATE50,
};

const buttonStyle = {
  ...inputStyle,
  backgroundColor: COLORS.SLATE500,
  color: COLORS.SLATE50,
  boxShadow: '0 1px 3px hsla(0, 0%, 0%, .2)',
};

const imgStyle = {
  height: '30vh',
};

const loadingContainerStyle = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(10,10,10,0.4)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)',
};

const Login = ({
  setId,
  setToken,
  id,
  token,
}) => {
  const [usuario, setUsuario] = useState('');
  const [pass, setPass] = useState('');
  const [isLogged, setIsLogged] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id > 0 && token.length > 0) {
      setIsLogged(true);
    }
  });

  const login = () => {
    setLoading(true);
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/login', { usuario, pass })
      .then((response) => {
        if (response.data.permissionLevel === 0 && response.data.secundario) {
          setAlertMsg('El usuario no cuenta con el permiso de dar salidas');
          setLoading(false);
          return;
        }
        setId(response.data.id);
        setToken(response.data.token);
        setIsLogged(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        switch (e.code) {
          case 'ERR_NETWORK':
            setAlertMsg('Sin conexión a internet.');
            break;
          case 'ERR_BAD_REQUEST':
            setAlertMsg('Usuario o contraseña incorrectos');
            break;
          default:
        }
      });
  };

  return (
    <div style={containerStyle}>
      {isLogged && <Navigate to="/main" />}
      <div style={formStyle}>
        <div style={imgStyle}>
          <img src={logo} alt="logo" style={{ height: '100%', width: '100%' }} />
        </div>
        <div style={inputContainerStyle}>
          <label htmlFor="usuario">
            <input
              type="text"
              name="usuario"
              id="usuario"
              placeholder="usuario"
              style={inputStyle}
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
          </label>
          <label htmlFor="pass">
            <input
              type="password"
              name="pass"
              id="pass"
              placeholder="pass"
              style={inputStyle}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && login()}
            />
          </label>
          {alertMsg !== '' && (
            <div style={alertStyle}>
              {alertMsg}
            </div>
          )}
          {loading && (
            <div style={loadingContainerStyle}>
              <div className="lds-dual-ring" />
            </div>
          )}
        </div>
        <input type="button" value="Entrar" style={buttonStyle} onClick={login} />
      </div>
    </div>
  );
};

export default Login;
